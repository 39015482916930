<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE BUYER SCHEDULE LIST</title>
    <section class="content-header">
      <h1>
        Update Buyer Schedule List
        <br />
        <h4>Please enter transaction data for the schedule buyer list</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Schedule Buyer List Transactions</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Update Buyer Schedule List</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Number </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.noord"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idxprodschbyr"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idxprodprosesd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Start Date </label>
                  <input
                    type="date"
                    v-model="isidata.datestart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">End Date </label>
                  <input
                    type="date"
                    v-model="isidata.dateend"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="isidata.notesbs"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/buyer-order">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        noord: "",
        idxprodschbyr: "",
        idxprodprosesd: "",
        datestart: "",
        dateend: "",
        notesbs: ""
      }
    };
  },
  created() {
    this.fetchdetail();
  },
  methods: {
    async fetchdetail() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      // const urlAPISaveDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order_schedule_proses/getbuyer_order_schedule_prosesbyid?id="+this.isidata.idxprosesprodukd;
      const urlAPIfetchDatadetail =
        this.$apiurl +
        "buyer_order_schedule_proses/getbuyer_order_schedule_prosesbyid?id=" +
        this.$route.params.id;
      axios
        .get(urlAPIfetchDatadetail, { headers })
        .then((resp) => {
          console.log(this.$route.params.id);
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, data not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.noord = resp.data.data.order_number;
            this.isidata.idxprodschbyr = resp.data.data.id;
            this.isidata.idxprodprosesd = resp.data.data.id_produk_d_proses;
            this.isidata.datestart = resp.data.data.date_start;
            this.isidata.dateend = resp.data.data.date_end;
            this.isidata.notesbs = resp.data.data.notes;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      var noord = this.isidata.noord;
      var idxprosprodukd = this.isidata.idxprodprosesd;
      var idxprodschbuyr = this.isidata.idxprodschbyr;
      var tglstart = this.isidata.datestart;
      var tglselesai = this.isidata.dateend;
      var catatanbs = this.isidata.notesbs;
      if (noord == "" || idxprosprodukd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Order Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglstart == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Start Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglselesai == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "End Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatanbs == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          order_number: this.isidata.noord,
          id_produk_d_proses: this.isidata.idxprodprosesd,
          date_start: this.isidata.datestart,
          date_end: this.isidata.dateend,
          notes: this.isidata.notesbs,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPISaveDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order_schedule_proses/updatebuyer_order_schedule_proses";
        const urlAPISaveDatadetail =
          this.$apiurl +
          "buyer_order_schedule_proses/updatebuyer_order_schedule_proses/" +
          idxprodschbuyr;

        axios
          .put(urlAPISaveDatadetail, paramdatadetail, { headers: headers })
          .then((respn) => {
            if (respn.data.status == true) {
              console.log(urlAPISaveDatadetail);
              console.log(respn);
              console.log(paramdatadetail);
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.$router.push({ name: "buyerorder" });
              this.loading = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
